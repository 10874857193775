import CheckBox from "../Checkbox";
import OutlineInput from "../OutlineInput";
import { Button, CircularProgress } from "@mui/material";
import desktopImageUrl from "../../Assets/Images/analytics.jpg";
import { useNavigate } from "react-router-dom";
import { useAuthenticateUser } from "./use-authenticate-user";
import { useForm, Controller } from "react-hook-form";

export type ILoginForm = {
  username: string;
  password: string;
};

export default function Home() {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<ILoginForm>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { authenticateUser } = useAuthenticateUser();
  const navigate = useNavigate();

  async function onSubmit(data: ILoginForm) {
    try {
      const result = await authenticateUser({
        username: data.username,
        password: data.password,
      });
      const accessToken = result?.access;
      const payload = { accessToken };
      localStorage.removeItem("X_AUTH_TOKEN");
      localStorage.setItem("X_AUTH_TOKEN", JSON.stringify(payload));
      navigate("/home", { replace: true });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="flex items-stretch flex-row bg-white min-h-screen max-h-screen overflow-hidden ">
      <div className="flex box-border p-0 m-0 w-[55.6%] h-100 rounded-tr-lg rounded-br-lg">
        <img
          src={desktopImageUrl}
          alt="desktop render"
          className="object-cover box-border h-full w-full rounded-tr-lg rounded-br-lg"
        />
      </div>
      <div className="flex flex-1 flex-col items-start h-100 bg-white pt-30 pr-15 pl-15 2xl:pt-60.25">
        <h3 className="text-h3-medium font-gilroy font-semibold text-header-light">
          Login
        </h3>
        <p className="text-normal text-light font-gilroy">
          Amet minim mollit non desert.
        </p>
        <form
          className="flex flex-col mt-10.75 w-full"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="flex mb-6">
            <Controller
              name="username"
              control={control}
              rules={{ required: "Username is required" }}
              render={({ field }) => (
                <OutlineInput
                  label={"Username"}
                  onHandleChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex mb-6">
            <Controller
              name="password"
              control={control}
              rules={{ required: "Password is required" }}
              render={({ field }) => (
                <OutlineInput
                  label={"Password"}
                  type="password"
                  onHandleChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex flex-row justify-between items-center mb-8">
            <div className="flex flex-row items-center justify-start gap-4">
              <CheckBox />
              <p className="font-normal font-gilroy leading-5 tracking-wider text-light">
                Remember me
              </p>
            </div>
            <p className="font-plus-jakarta-sans font-semibold tracking-wider leading-5 text-normal text-blue">
              Forgot password
            </p>
          </div>
          <div className="flex">
            <Button
              classes={{
                root: `!bg-blue !w-full !text-white !pt-4.5 !pb-4.5 leading-5 !normal-case
                 !tracking-widest !text-medium !font-gilroy !font-medium `,
              }}
              type="submit"
            >
              {isSubmitting ? <CircularProgress size={25} /> : "Sign in"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
