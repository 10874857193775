import { Button, CircularProgress, Switch } from "@mui/material";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranscribe } from "./use-transcribe";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import React from "react";
import TextAreaInput from "../../Components/TextAreaInput";
import ModuleWrapper from "../../Components/ModuleWrapper";
import { useCheckCompliance } from "./use-check-compliance";
import DataTable, { ITableColumn } from "../../Components/DataTable";
import FileUploadInput from "../../Components/FileUploadInput";
export type AddAudioProps = {
  title: string;
  description: string;
  transcript?: string;
};

export const PlayGround = () => {
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioURL, setAudioURL] = useState<string | null>("");
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const {
    transcript,
    onTranscribe,
    isLoading: isTranscribing,
  } = useTranscribe();

  const {
    isLoading: isCheckingCompliance,
    onCheckCompliance,
    report,
  } = useCheckCompliance();
  const {
    formState: { isSubmitting },
    control,
    watch,
  } = useForm<AddAudioProps>();
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const submitTranscription = () => {
    const formData = new FormData();

    if (audioBlob || audioFile) {
      const file = new File(
        [audioBlob as Blob],
        `${new Date().getTime()}.${audioBlob?.type}`,
        {
          type: audioBlob?.type,
        }
      );
      formData.append("file", showUpload ? audioFile as File : file);
      formData.append("audio_source", "Mwendo Speech");
      onTranscribe(formData);
    }
  };

  const submitComplianceCheck = useCallback(() => {
    onCheckCompliance({
      transcription: transcript as string,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript]);

  const recorderControls = useAudioRecorder();

  const addAudioElement = (blob: any) => {
    const audioURL = URL.createObjectURL(blob);
    if (audioRef.current) {
      audioRef.current.src = audioURL;
      audioRef.current.controls = true;
    }
    setAudioBlob(blob);
    setAudioURL(audioURL);
  };

  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">
        Playground
      </h2>
      <form className="flex flex-col gap-4 w-full mt-6.5 pb-4">
        <div className="flex flex-col items-start justify-start w-[700px] gap-3">
          <div className="flex flex-col gap-2 w-full items-start justify-start">
            <div className="flex flex-row items-center justify-start gap-2">
              <Switch
                checked={showUpload}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setShowUpload(event.target.checked)
                }
              />
              <p className="text-faint-black font-plus-jakarta text-base font-medium">
                {showUpload ? "Upload Audio" : "Record Audio"}
              </p>
            </div>
            {showUpload && (
              <div className="flex flex-col gap-2 w-full items-start justify-start">
                <p className="text-text-field font-gilroy text-label  mb-2 capitalize">
                  Upload Audio
                </p>
                <FileUploadInput
                  {...{
                    label: "",
                    value: audioFile,
                    onChange: (event) => {
                      setAudioFile(
                        event?.target?.files ? event?.target?.files[0] : null
                      );
                    },
                    name: "audio",
                  }}
                />
                {audioURL && (
                  <audio controls ref={audioRef}>
                    <source src={audioURL} />
                  </audio>
                )}
              </div>
            )}
          </div>
          {!showUpload && (
            <div className="flex flex-col gap-2 w-full items-start justify-start">
              <p className="text-text-field font-gilroy text-label  mb-2 capitalize">
                Audio Source
              </p>
              <AudioRecorder
                onRecordingComplete={(blob: Blob) => {
                  addAudioElement(blob);
                }}
                recorderControls={recorderControls}
                downloadFileExtension="mp3"
              />
              {audioURL && (
                <audio controls ref={audioRef}>
                  <source src={audioURL} />
                </audio>
              )}
            </div>
          )}
          {transcript && (
            <Controller
              name="transcript"
              control={control}
              defaultValue={transcript}
              render={({ field }) => (
                <TextAreaInput
                  name="transcript"
                  label={"Transcript"}
                  value={field.value}
                  onHandleChange={field.onChange}
                />
              )}
            />
          )}
          {report && <MediaStreamAnalysisComplete report={report} />}
        </div>
        <div className="flex  flex-row w-full items-center justify-end gap-2">
          {(audioBlob || audioFile)  && (
            <Button
              className="!bg-blue !px-6.25 !py-3.5 !capitalize !text-white"
              onClick={submitTranscription}
              disabled={isTranscribing}
            >
              {!isTranscribing ? (
                "Transcribe"
              ) : (
                <CircularProgress
                  size={23}
                  classes={{
                    root: "!text-white",
                  }}
                />
              )}
            </Button>
          )}
          {transcript && (
            <Button
              className="!bg-blue !px-6.25 !py-3.5 !capitalize !text-white"
              type="submit"
              disabled={isSubmitting || isCheckingCompliance}
              onClick={submitComplianceCheck}
            >
              {!isCheckingCompliance ? (
                "Check Compliance"
              ) : (
                <CircularProgress
                  size={23}
                  classes={{
                    root: "!text-white",
                  }}
                />
              )}
            </Button>
          )}
        </div>
      </form>
    </ModuleWrapper>
  );
};

export const MediaStreamAnalysisComplete = ({ report }: { report: any }) => {
  const summary = report?.summary || null;
  const complianceReport: {
    parameter: string;
    detected: string;
    explanation: string;
  }[] = [];
  report?.compliance_report &&
    Object.keys(report?.compliance_report).forEach((key) => {
      complianceReport.push({
        parameter: key?.replace(/_/g, " "),
        detected: report?.compliance_report?.[key]?.["detected"] ? "Yes" : "No",
        explanation: report?.compliance_report?.[key]?.["explanation"],
      });
    });

  const tableColumns: ITableColumn[] = [
    {
      name: "Parameter",
      dataKey: "parameter",
    },
    {
      name: "Detected",
      dataKey: "detected",
    },
    {
      name: "Explanation",
      dataKey: "explanation",
    },
  ];

  const namedEntities: {
    entity: string;
    value: string;
  }[] = [];

  report?.named_entities &&
    Object.keys(report?.named_entities).forEach((key) => {
      namedEntities.push({
        entity: key?.replace(/_/g, " "),
        value: report?.named_entities?.[key]?.join(", "),
      });
    });

  const namedEntitiesTableColumns: ITableColumn[] = [
    {
      name: "Entity",
      dataKey: "entity",
    },
    {
      name: "Value",
      dataKey: "value",
    },
  ];

  return (
    <div className="flex flex-col gap-4.25">
      {summary && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Summary
          </p>
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            {summary}
          </p>
        </div>
      )}
      {complianceReport?.length && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Compliance report
          </p>
          <DataTable
            columns={tableColumns}
            data={complianceReport}
            header="Compliance Report"
            hideHeaderSection
            hidePagination
          />
        </div>
      )}

      {namedEntities?.length && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Named Entities
          </p>
          <DataTable
            columns={namedEntitiesTableColumns}
            data={namedEntities}
            header="Named Entities"
            hideHeaderSection
            hidePagination
          />
        </div>
      )}
    </div>
  );
};
