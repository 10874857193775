import { Pagination, PaginationItem } from "@mui/material";

export type TablePaginationProps = {
    totalPages?: number
    currentPage?: number
    totalEntries?: number
    startEntry?: number
    endEntry?: number
    limit?: number
    setPage?: (page: number) => void
}

export default function TablePagination({totalPages=50, currentPage=1, totalEntries=57,limit=10,setPage}: TablePaginationProps) {
    const startEntry = (currentPage - 1) * limit + 1;
    const endEntry = Math.min(currentPage * limit, totalEntries);

    return (
        <div className="flex flex-row justify-between items-center h-15.25">
            <p className="!text-input-field !font-gilroy !font-medium !text-header">
                Showing {startEntry} to {endEntry} of {totalEntries} entries
            </p>
            <Pagination
                className="!font-gilroy"
                count={totalPages}
                defaultPage={currentPage}
                onChange={(_, page) => {setPage && setPage(page)}}
                renderItem={(item) => (
                    <PaginationItem
                        {...item}
                        classes={{
                            root: "!font-gilroy !text-input-field !font-medium text-pagination",
                            selected:
                                "!bg-white !w-10.75 !h-10.75 !shadow-pagination !rounded-mdl !text-blue",
                            icon: "!text-blue !disabled:text-disabled",
                        }}
                    />
                )}
            />
        </div>
    )
}