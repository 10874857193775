import { useState } from "react";
import { complianceAPI } from "../../rest_client";


export function useCheckCompliance(){
    const [report, setReport] = useState<Record<string, any> | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onCheckCompliance = async ({transcription, source}: {
        transcription: string;
        source?: string
    }) => {
      try {
        setIsLoading(true);
        const data = await complianceAPI({
            transcription,
            source
        })
        console.log('Transcriptions: ', data);
        setIsLoading(false);
        setReport(data);
        return;
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        return;
      }
    };
    return {
      report,
      onCheckCompliance,
      isLoading
    };
}