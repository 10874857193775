import ModuleWrapper from "../../Components/ModuleWrapper";
import { useState } from "react";
import clsx from "clsx";
import { ReactComponent as RolesIcon } from "../../Assets/Images/roles.svg";
import { ButtonBase } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";


export default function AdminPanel() {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate()
  const tabs: { id: number; label: string; icon: React.ReactNode }[] = [
    {
      id: 0,
      label: "Role",
      icon: <RolesIcon />,
    },
    /*
    {
      id: 1,
      label: "Permissions",
      icon: <PermissionsIcon />,
    },*/
  ];

  function onSwitchTab(id: number) {
    setActiveTab(id);
    if(id === 0){
        navigate('/admin-panel/roles')
    }
    if(id === 1){
        navigate('/admin-panel/permissions')
    }
  }
  return (
    <ModuleWrapper>
      <h2 className="font-gilroy text-h3-small font-bold text-page-header mb-3">
        Admin Settings
      </h2>
      <div className="flex flex-row items-start gap-6.5">
        <div className="flex flex-col w-60.25 min-h-76.25 bg-white rounded p-0">
          {tabs.map(({ id, label, icon }, index) => (
            <ButtonBase
              className={clsx(
                "flex !flex-row !justify-start  !items-start !py-4 !gap-4 !pl-5.25  w-full font-medium text-lg hover:cursor-pointer",
                {
                  "!bg-gray-4 !text-gray-6 ": activeTab !== id,
                  "!bg-secondary !text-white !rounded-tl-sm !rounded-bl-sm":
                    activeTab === id,
                }
              )}
              onClick={() => onSwitchTab(id)}
              key={index}
            >
              {icon}
              <span>{label}</span>
            </ButtonBase>
          ))}
        </div>
        <Outlet />
      </div>
    </ModuleWrapper>
  );
}
