import { useState } from 'react';
import { transcribeAPI } from '../../rest_client';

export function useTranscribe() {
  const [transcript, setTranscript] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onTranscribe = async (formData: FormData) => {
    try {
      setIsLoading(true);
      const transcriptions = await transcribeAPI(formData);
      console.log('Transcriptions: ', transcriptions);
      setIsLoading(false);
      const transcript = transcriptions?.results[0]?.transcript;
      setTranscript(transcript);
      return;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return;
    }
  };
  return {
    transcript,
    onTranscribe,
    isLoading
  };
}