import ModuleWrapper from "../../Components/ModuleWrapper";
import DataTable, { ITableColumn } from "../../Components/DataTable";
import StatusButton from "../../Components/StatusButton";
import { ActionListProps } from "../../Components/ActionList";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as DotsIcons } from "../../Assets/Images/Dots.svg";
import { useFetchJobs } from "../Jobs/use-fetch-jobs";
import { toYYMMdd } from "../../utils/date";
import ExecutionModal from "./executions-modal";


export type IExecutionItem ={
    date: string;
    currentStep: string;
    executions: any[];
}

export default function JobItem() {
    const [executionItems, setExecutionItems] = useState<IExecutionItem[]>([]);
  const navigate = useNavigate();
  const params = useParams();
  const jobId = params.jobId;
  const { fetchJobs, jobs , loading} = useFetchJobs();

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const combineSteps: {
      [key: string]: any;
    } = {};

    const currentJob = jobs.find((job) => job.id === jobId);

    currentJob?.executions?.forEach((execution: any) => {
      const date = execution?.created_at
        ? toYYMMdd(execution.created_at)
        : "N/A";
      if (date) {
        combineSteps[date] = combineSteps[date]
          ? [...combineSteps[date], execution]
          : [execution];
      }
    });

    const executionItems: IExecutionItem[] = [];

    Object.keys(combineSteps).forEach((key) => {
        const currentStep = combineSteps[key][0]?.status?.replace(/_/g, " ");   
        executionItems.push({
          date: key,
          currentStep,
          executions: combineSteps[key],
        });
    }
    );
    setExecutionItems(executionItems);
    console.log("Combine steps ", combineSteps);
  }, [jobs, jobId]);

  function onClickCreateButton() {
    navigate("/create-job");
  }
  const tableColumns: ITableColumn[] = [
    {
      name: "Date",
      dataKey: "date",
    },
    {
      name: "Current step",
      dataKey: "currentStep",
    },

    {
      name: "Action",
      dataKey: "action",
    },
  ];

  console.log("Execution items ", executionItems);

  const tableData = executionItems.map((item, index: number) => ({
    ...item,
    action: <ExecutionModal execution={item} />,
  }));

  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">
        Monitoring Tasks Executions
      </h2>
      <div className="flex w-full mt-6.5 pb-4">
        <DataTable
          columns={tableColumns}
          data={tableData}
          header="Monitoring Tasks Executions"
          loading={loading}
    
        />
      </div>
    </ModuleWrapper>
  );
}
