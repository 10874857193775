import ModuleWrapper from "../../Components/ModuleWrapper";
import DataTable, { ITableColumn } from "../../Components/DataTable";
import StatusButton from "../../Components/StatusButton";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useFetchJobs } from "./use-fetch-jobs";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as DotsIcons } from "../../Assets/Images/Dots.svg";

function ActionList({ jobId }: { jobId: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = useCallback(
    (jobId: string) => {
      navigate(`/jobs/${jobId}`);
    },
    [navigate]
  );

  return (
    <div className="inline-flex">
      <IconButton onClick={handleClick}>
        <DotsIcons />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: "!rounded-sm !shadow-dialog",
        }}
      >
        <div className="w-57.5 !h-48.5 !py-6">
          <MenuItem
            onClick={() => handleNavigate(jobId)}
            className="!text-normal !font-gilroy !text-grey"
          >
            View Execution
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
}

export default function Jobs() {
  const navigate = useNavigate();
  const {
    fetchJobs,
    loading,
    jobs,
    totalItems,
    totalPages,
    page,
    setPage,
    limit,
  } = useFetchJobs();

  useEffect(() => {
    fetchJobs();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function onClickCreateButton() {
    navigate("/create-job");
  }
  const tableColumns: ITableColumn[] = [
    {
      name: "Title",
      dataKey: "title",
    },
    {
      name: "Type",
      dataKey: "job_type",
    },
    {
      name: "Source",
      dataKey: "source_system",
    },
    {
      name: "Number of Executions",
      dataKey: "job_count",
    },
    {
      name: "Start Time",
      dataKey: "start_time",
    },
    {
      name: "End Time",
      dataKey: "end_time",
    },
    {
      name: "Frequency",
      dataKey: "frequency",
    },

    {
      name: "Action",
      dataKey: "action",
    },
  ];

  const tableData = jobs.map((item: any, index: number) => ({
    ...item,
    action: <ActionList {...{ jobId: item.id }} />,
    status: (
      <StatusButton
        label={item?.isActive ? "active" : "blocked"}
        active={item?.isActive}
      />
    ),
  }));

  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">
        Monitoring Tasks
      </h2>
      <div className="flex w-full mt-6.5 pb-4">
        <DataTable
          columns={tableColumns}
          data={tableData}
          header="Monitoring Tasks"
          onClickCreateButton={onClickCreateButton}
          createButtonText="Add Monitoring Task"
          loading={loading}
          {...{
            totalItems,
            totalPages,
            page,
            setPage: (page: number) => {
              setPage(page);
            },
            limit,
          }}
        />
      </div>
    </ModuleWrapper>
  );
}
