import { OutlinedInput } from "@mui/material";
import { ChangeEvent, useState } from "react";
import clsx from "clsx";

export type TexFieldProps = {
  children?: any;
  label: string;
  name: string;
  type?: string;
  value?: string | number;
  onHandleChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export default function TextAreaInput({ label, type, name, value,onHandleChange }: TexFieldProps) {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="w-full">
      <p className="text-text-field font-gilroy text-label  mb-2 capitalize">{label}</p>
      <OutlinedInput
        notched={false}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        multiline
        type={type || "text"}
        autoComplete="off"
        name={name}
        value={value}
        classes={{
          root: "w-full w-100 !p-0",
          notchedOutline: clsx({
            "rounded-md": true,
            "!border-field-border": !focused,
            "!border-blue": focused,
          }),
          input:
            "!text-normal !text-grey !pl-5.75 !pr-2.25 !pt-4.25 !pb-2.75  !font-gilroy ",
        }}
        onChange={onHandleChange}
        
      />
    </div>
  );
}
