import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as DotsIcons } from "../../Assets/Images/Dots.svg";
import { useState } from "react";

export type ActionListProps = {
  children: React.ReactNode;
};

export type ActionListItemProps = {
  onHandleClick: () => void;
  children: React.ReactNode | string;
};

export const ActionListItem = ({
  onHandleClick,
  children,
}: ActionListItemProps) => {
  return (
    <MenuItem
      className="!px-5 !py-2.75 hover:text-blue hover:bg-blue/13 !font-gilroy font-medium text-base"
      onClick={onHandleClick}
    >
      {children}
    </MenuItem>
  );
};

export default function ActionList({ children }: ActionListProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="inline-flex">
      <IconButton onClick={handleClick}>
        <DotsIcons />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: "!rounded-sm !shadow-dialog",
        }}
      >
        <div className="w-57.5 !h-48.5 !py-6">{children}</div>
      </Menu>
    </div>
  );
}
