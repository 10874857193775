import { useCallback, useState } from "react";
import { getRadioListApi } from "../../rest_client";
import { usePagination } from "../../Common/hooks/use-pagination";

export type IRadio = {
  id: string;
  name: string;
  description: string;
  location: string;
  url: string;
  rating: number;
  job_count: number;
  created_at: string;
  updated_at: string;
};

export function useFetchRadios() {
  const [loading, setLoading] = useState<boolean>(false);
  const [radios, setRadios] = useState<IRadio[]>([]);
  const [isErrored, setIsErrored] = useState<boolean>(false);
  const {
    setTotalItems,
    setTotalPages,
    totalItems,
    totalPages,
    page,
    limit,
    setPage,
  } = usePagination();

  // function to choose a random number between 10 and 50
  function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const fetchRadios = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getRadioListApi({ limit, offset: page - 1 });
      setLoading(false);
      if (response) {
        setTotalItems(response.total_items);
        setTotalPages(
          response?.total_items ? Math.ceil(response.total_items / limit) : 0
        );
        const radios = response.items.map((item: any, index: number) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            url: item.url,
            location: "Unknown",
            rating: 20,
            job_count: getRandomInt(10, 30),
            created_at: item.created_at,
            updated_at: item.updated_at,
          };
        });
        setRadios(radios);
      } else {
        setIsErrored(true);
      }
    } catch (error) {
      setLoading(false);
      setIsErrored(true);
      console.log(error);
    }
  }, [limit, page, setTotalItems, setTotalPages]);

  return {
    fetchRadios,
    loading,
    radios,
    isErrored,
    totalItems,
    totalPages,
    page,
    limit,
    setPage,
  };
}
