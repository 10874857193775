import { OutlinedInput } from "@mui/material";
import { ChangeEvent, useState } from "react";
import clsx from "clsx";

export type TexFieldProps = {
  children?: any;
  label: string;
  name: string;
  type?: string;
  value?: string | number;
  onHandleChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  disabled?: boolean;
  minValue?: number | string | null;
  maxValue?: number | string | null;
};

export default function TextField({
  label,
  type,
  name,
  value,
  onHandleChange,
  disabled,
  minValue,
  maxValue,
}: TexFieldProps) {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="w-full">
      <p className="text-text-field font-gilroy text-label  mb-2 capitalize">
        {label}
      </p>
      <OutlinedInput
        notched={false}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        type={type || "text"}
        autoComplete="off"
        name={name}
        value={value}
        inputProps={{
          inputProps: {
            max: maxValue,
          },
        }}
        classes={{
          root: "w-full w-100",
          notchedOutline: clsx({
            "rounded-md": true,
            "!border-field-border": !focused,
            "!border-blue": focused,
          }),
          input:
            "!text-normal !text-grey !pl-5.75 !pt-5 !pb-2.75  !font-gilroy",
        }}
        onChange={onHandleChange}
        disabled={disabled}
      />
    </div>
  );
}
