import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

export type AlertBoxProps = {
  isOpen: boolean;
  duration?: number;
  mode: "success" | "error";
  message: string;
};

export default function AlertBox({
  isOpen,
  duration,
  mode,
  message,
}: AlertBoxProps) {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function handleClose() {
    setOpen(false);
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert className="!font-gilroy" severity={mode === "error" ? "error" : "success"}>{message}</Alert>
    </Snackbar>
  );
}
