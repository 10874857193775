import { createBrowserRouter } from "react-router-dom";
import Home from "./Components/Home";
import DashboardWrapper from "./Components/DashboardWrapper";
import AdminPanel from "./Containers/AdminPanel";
import Roles from "./Containers/AdminPanel/Roles";
import Permissions from "./Containers/AdminPanel/Permissions";
import RoleForm from "./Containers/AdminPanel/RoleForm";
import Radios from "./Containers/Radios";
import Jobs from "./Containers/Jobs";
import CreateJobForm from "./Containers/CreateJob";
import JobItem from "./Containers/JobExecutions";
import { PlayGround } from "./Containers/PlayGround";
import Televisions from "./Containers/Televisions";
import OnlineMediaSources from "./Containers/OnlineMediaSources";
import OfflineMediaSources from "./Containers/OfflineMediaSources";

const router = createBrowserRouter([
  {
    path: "/not-found",
    element: (
      <>
        <h1>Not found</h1>
      </>
    ),
  },
  {
    path: "/not-authorized",
    element: (
      <>
        <h1>Not Authorized</h1>
      </>
    ),
  },
  {
    path: "/login",
    element: <Home />,
  },
  {
    path: "/",
    element: <DashboardWrapper />,
    children: [
      {
        index: true,
        element: <Radios />,
      },
      {
        path: "home",
        element: <Radios />,
      },
      {
        path: "radios",
        element: <Radios />,
      },
      {
        path: "jobs",
        element: <Jobs />,
      },
      {
        path: "jobs/:jobId",
        element: <JobItem />,
      },
      {
        path: "create-job",
        element: <CreateJobForm />,
      },
      {
        path: "playground",
        element: <PlayGround />,
      },
      {
        path: "televisions",
        element: <Televisions />,
      },
      {
        path: "online-media-sources",
        element: <OnlineMediaSources />,
      },
      {
        path: "offline-media-sources",
        element: <OfflineMediaSources />,
      },
      {
        path: "admin-panel",
        element: <AdminPanel />,
        children: [
          {
            path: "roles",
            element: <Roles />,
            children: [
              {
                path: "create",
                element: <RoleForm />,
              },
              {
                path: "edit",
                element: <RoleForm />,
              },
            ],
          },
          {
            path: "permissions",
            element: <Permissions />,
          },
        ],
      },
    ],
  },
]);

export default router;
