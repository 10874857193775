import {DateTime} from "luxon"

export function toYYMMdd(isoDate: string | null){
    if(!isoDate){
        return ""
    }
    return DateTime.fromISO(isoDate).toISODate()
}


export function toYYMMddHHmmss(isoDate: string | null){
    if(!isoDate){
        return ""
    }
    return DateTime.fromISO(isoDate).toFormat("yyyy-MM-dd HH:mm:ss")
}