export const VolumeUpIcon = () => {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2576 3.00001C12.301 3.00001 13.2741 3.75154 13.6254 4.95612C13.7543 5.45964 13.8068 5.96423 13.8571 6.45057L13.9043 6.89289C14.0846 8.36909 14.0846 15.6213 13.9043 17.1082L13.8571 17.5666C13.812 18.0293 13.7659 18.5082 13.6327 19.0192C13.2814 20.2206 12.345 21 11.2817 21C11.2482 21 11.2146 21 11.1769 20.9989C10.5897 20.9989 9.95107 20.6382 9.58931 20.3236L6.06287 17.383H4.25194C2.85416 17.383 1.83073 16.3706 1.64513 14.8043C1.43122 13.2379 1.47316 10.5506 1.64513 9.12706C1.84751 7.64441 2.91918 6.61698 4.25194 6.61698H6.06287L9.5222 3.71933C9.93954 3.35645 10.6757 2.99787 11.2576 3.00001ZM19.7901 3.22107C20.2074 2.92476 20.781 3.03319 21.0673 3.45727C22.636 5.76658 23.5 8.80165 23.5 11.9999C23.5 15.2003 22.636 18.2343 21.0673 20.5436C20.8963 20.7959 20.6143 20.9473 20.3133 20.9473C20.1267 20.9473 19.9463 20.8893 19.7911 20.7798C19.3759 20.4824 19.2731 19.8973 19.5604 19.4722C20.9173 17.4731 21.665 14.8192 21.665 11.9999C21.665 9.1817 20.9173 6.52776 19.5604 4.52872C19.2731 4.10465 19.3759 3.51846 19.7901 3.22107ZM16.6283 6.2584C17.0467 5.96316 17.6182 6.06944 17.9066 6.49459C18.9195 7.98689 19.4784 9.94192 19.4784 12C19.4784 14.0581 18.9195 16.0131 17.9066 17.5054C17.7346 17.7577 17.4536 17.9091 17.1526 17.9091C16.966 17.9091 16.7846 17.8511 16.6294 17.7416C16.2141 17.4442 16.1114 16.8581 16.3997 16.434C17.2019 15.2519 17.6434 13.677 17.6434 12C17.6434 10.322 17.2019 8.74807 16.3997 7.56604C16.1114 7.14197 16.2141 6.55578 16.6283 6.2584Z"
          fill="#29353D"
        />
      </svg>
    );
  };