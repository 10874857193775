export type ModuleWrapperProps = {
  children: React.ReactNode;
};

export default function ModuleWrapper({ children }: ModuleWrapperProps) {
  return (
    <div className="flex flex-col box-border  pt-8 pl-10 pr-7.25 bg-paper h-[calc(100vh_-_90px)] overflow-y-auto overflow-x-hidden">
      {children}
    </div>
  );
}
