import { useState } from "react";
import { authenticateUserApi } from "../../rest_client";

export function useAuthenticateUser() {
  const [loading, setLoading] = useState<boolean>(false);
  const [isErrored, setIsErrored] = useState<boolean>(false);
  const authenticateUser = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      setLoading(true);
      const response = await authenticateUserApi({ username, password });
      setLoading(false);
      if (response) {
        return response
      } else {
        setIsErrored(true);
        return undefined
      }
    } catch (error) {
        setLoading(false);
        setIsErrored(true);
      console.log(error);
    }
  };

  return { authenticateUser, loading, isErrored };
}
