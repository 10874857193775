import axios from "axios";
import { getAuthToken } from "./client";
import qs from "qs"

const httpInstance = axios.create({
  baseURL: "https://schedule.mwendospeech.com/v1"
});


const backendInstance = axios.create({
  baseURL: 'https://backend.mwendospeech.com',
});

export type FileUploadInput = {
  name: string;
  file: File;
};

export async function uploadFileToStorage(files: FileUploadInput[]) {
  const formData = new FormData();
  for (const { name, file } of files) {
    formData.append(name, file);
  }

  const response = await httpInstance({
    url: "/upload",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response?.data
}



export async function authenticateUserApi({username, password}:{username: string; password: string}) {
  const response = await httpInstance({
    url: "/auth/token/",
    method: "POST",
    data: {
      username,
      password,
    },
  });
  return response?.data;
}


export async function verifyAuthTokenApi({token}:{token:string}){
  const response = await httpInstance({
    url: "/auth/token/verify/",
    method: "POST",
    data: {token},
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return response.data;
}


export async function getRadioListApi({limit, offset}:{limit: number; offset: number}){ 
  const response = await httpInstance({
    url: "/media/list/",
    method: "GET",
    params:{limit, offset},
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  })

  return response?.data || {}

}


export async function fetchJobsApi({limit, offset}:{limit: number; offset: number}){
  const response = await httpInstance({
    url: "/jobs/list/",
    method: "GET",
    params:{limit, offset},
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  })

  return response?.data || {}
}



export async function createJobApi(payload: any){
  const response = await httpInstance({
    url: "/jobs/create/",
    method: "POST",
    data: payload,
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });


  return response?.data || {}
}


export async function transcribeAPI(
  formData: FormData
): Promise<{ results: { transcript: string; file_name: string }[] }> {
  const response = await backendInstance({
    url: '/transcribe/test',
    method: 'POST',
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}


export async function complianceAPI(
 {transcription, source}: {transcription: string;source?: string}
): Promise<any> {
  const urlEncodedData = qs.stringify( {
    transcription,
    source
  });

  const response = await backendInstance.post('/check-compliance-test', urlEncodedData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.data;
}



