import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { PauseCircleIcon, SpeakerXMarkIcon } from "@heroicons/react/24/solid";
import { PlayIcon } from "./play";
import { VolumeUpIcon } from "./volume-up";

export type AudioPlayerUIProps = {
  showSkipControls?: boolean;
  showJumpControls?: boolean;
  fullWidth?: boolean;
  audioFile: string;
};

export const AudioPlayerUI = ({
  showJumpControls = false,
  showSkipControls = false,
  fullWidth = false,
  audioFile,
}: AudioPlayerUIProps) => {
  return (
    <div>
      <AudioPlayer
        autoPlay={false}
        src={audioFile}
        onPlay={(e) => console.log("onPlay")}
        showSkipControls={showJumpControls}
        showJumpControls={showSkipControls}
        loop={false}
        customAdditionalControls={[]}
        layout="horizontal"
        style={{ width: !fullWidth ? "350px" : "800px" }}
        className="border border-faint-black rounded-full !px-[22px] !py-0 !font-plus-jakarta !text-xs !bg-white"
        customIcons={{
          play: <PlayIcon />,
          volume: <VolumeUpIcon />,
          pause: <PauseCircleIcon className="w-7 h-7 text-faint-black" />,
          volumeMute: <SpeakerXMarkIcon className="w-6 h-6 text-faint-black" />,
        }}
        // other props here
      />
    </div>
  );
};