import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import clsx from "clsx";
import { Link } from "react-router-dom";

export type INavItem = {
  Icon: React.ReactNode;
  link?: string;
  label: string;
  children?: {
    Icon: React.ReactNode;
    label: string;
    link?: string;
  }[];
};

export type NavItemProps = {
  id: number;
  onSelectMenu: (label: string) => void;
  selectedMenu: string;
} & INavItem;

export default function NavItem({
  id,
  Icon,
  label,
  link,
  children,
  onSelectMenu,
  selectedMenu,
}: NavItemProps) {
  const [open, setOpen] = useState<boolean>(false);
  function handleClick(label: string) {
    setOpen(!open);
    onSelectMenu(label);
  }
  function onSelectedSubmenu(label: string) {
    onSelectMenu(label);
  }
  const itemKey = `${id}-${label}`;

  console.log("Label ", `${label} ${link}`)
  return (
    <>
      <Link to={link || "/home"}>
        <ListItemButton
          onClick={() => handleClick(itemKey)}
          classes={{
            root: clsx("!pl-3.5 !py-4 !pr-6.5 !w-full", {
              "!rounded-tl-sm !rounded-bl-sm !bg-menu-selected":
                itemKey === selectedMenu,
            }),
          }}
        >
          <ListItemIcon
            classes={{
              root: "!mr-2 !min-w-[24px] !w-[24px] !min-h-[24px] !h-[24px]",
            }}
          >
            {Icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              root: "!text-medium !font-girloy !font-medium !text-menu-item !capitalize",
              primary: "!font-gilroy",
            }}
            primary={label}
          />
          {children &&
            (open ? (
              <ExpandLess className="!text-light-blue" />
            ) : (
              <ExpandMore className="!text-light-blue" />
            ))}
        </ListItemButton>
      </Link>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children &&
            children.map(({ Icon, label,link }, index) => {
              const itemKey = `${index}-${label}`;
              return (
                <Link to={link || "/home"}>
                  <ListItemButton
                    onClick={() => onSelectedSubmenu(itemKey)}
                    classes={{
                      root: clsx("!pl-7.5 !py-4 !pr-6.5 !w-full", {
                        "!rounded-tl-sm !rounded-bl-sm !bg-menu-selected":
                          itemKey === selectedMenu,
                      }),
                    }}
                    key={index}
                  >
                    <ListItemIcon
                      classes={{
                        root: "!mr-2 !min-w-[24px] !w-[24px] !min-h-[24px] !h-[24px]",
                      }}
                    >
                      {Icon}
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: "!text-medium !font-girloy !font-medium !text-menu-item !capitalize",
                        primary: "!font-gilroy",
                      }}
                      primary={label}
                    />
                  </ListItemButton>
                </Link>
              );
            })}
        </List>
      </Collapse>
    </>
  );
}
