import { Button, CircularProgress, Modal, Paper } from "@mui/material";
import clsx from "clsx";
import React from "react";

export type ModalFormProps = {
  open: boolean;
  onHandleClose: () => void;
  onHandleSubmit: () => void;
  children: React.ReactNode;
  loading?: boolean;
  isTop?:boolean
};

export default function ModalForm({
  open,
  onHandleClose,
  onHandleSubmit,
  children,
  loading,
  isTop
}: ModalFormProps) {
  return (
    <Modal className="!outline-none !border-none" open={open} onClose={onHandleClose}>
      <div className={clsx("flex flex-col justify-start items-start w-full h-full pl-75.25 pr-8 pt-44 bg-header/30 !border-none !outline-none", {
        "pt-20": isTop
      })}>
        <Paper className="w-full !shadow-none !box-border px-24.75 py-12.5 !rounded-sm ">
          <div className="flex flex-col">{children}</div>
          <div className="flex  flex-row w-full justify-end gap-6.5 mt-6.5">
            <Button
              className="!w-33.75 !box-border !font-gilroy !px-5.25 !py-3.5 !capitalize !text-input-field !text-header"
              onClick={onHandleClose}
            >
              Cancel
            </Button>

            <Button
              className="!w-33.75 !box-border !font-gilroy !text-white !px-5.25 !py-3.5 !capitalize !bg-blue !rounded-md !text-input-field"
              onClick={onHandleSubmit}
            >
              {!loading ? (
                "Save"
              ) : (
                <CircularProgress
                  size={23}
                  classes={{
                    root: "!text-white",
                  }}
                />
              )}
            </Button>
          </div>
        </Paper>
      </div>
    </Modal>
  );
}
