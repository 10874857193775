import { Button, IconButton, Modal, Paper } from "@mui/material";
import clsx from "clsx";
import React, { useCallback } from "react";
import { IExecutionItem } from ".";
import TabContainer, { ITab } from "../../Components/TabContainer";
import { toYYMMddHHmmss } from "../../utils/date";
import { AudioPlayerUI } from "./audio-player";
import DataTable, { ITableColumn } from "../../Components/DataTable";
import { XMarkIcon } from "@heroicons/react/20/solid";
import json5 from "json5";
import { jsonrepair } from "jsonrepair";

export type ModalFormProps = {
  execution: IExecutionItem;
};

export default function ExecutionModal({ execution }: ModalFormProps) {
  const [open, setOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const onHandleClose = () => {
    setOpen(false);
  };
  const onHandleOpen = () => {
    setOpen(true);
  };
  const tabs: ITab[] = execution.executions.map((execution, index) => {
    return {
      name: execution?.status?.replace(/_/g, " ") || "N/A",
    };
  });

  const currentStepContent = execution?.executions?.[activeTab];
  const filePath =
    execution?.executions?.find(
      (execution) => execution?.status === "MEDIA_STREAM_UPLOAD_COMPLETED"
    )?.s3_file_path || null;

  const renderContent = useCallback(() => {
    if (currentStepContent?.status === "MEDIA_STREAM_RECORDING_COMPLETED") {
      return <MediaStreamRecordCompleted execution={currentStepContent} />;
    }
    if (currentStepContent?.status === "MEDIA_STREAM_UPLOAD_COMPLETED") {
      return <MediaStreamUploadCompleted execution={currentStepContent} />;
    }
    if (currentStepContent?.status === "MEDIA_STREAM_TRANSCRIBING_COMPLETED") {
      return (
        <MediaStreamTranscribingComplete
          execution={currentStepContent}
          filePath={filePath}
        />
      );
    }
    if (currentStepContent?.status === "MEDIA_STREAM_ANALYZING_COMPLETED") {
      return (
        <MediaStreamAnalysisComplete
          execution={currentStepContent}
          filePath={filePath}
        />
      );
    }

    return <div>Content</div>;
  }, [currentStepContent, filePath]);

  return (
    <>
      <Button className="!capitalize text-sm " onClick={() => onHandleOpen()}>
        {" "}
        View
      </Button>
      <Modal
        className="!outline-none !border-none"
        open={open}
        onClose={onHandleClose}
      >
        <div
          className={clsx(
            "flex flex-col justify-start items-start w-full h-full !px-20  pt-20 bg-header/30 !border-none !outline-none",
            {}
          )}
        >
          <Paper className="w-full !shadow-none overflow-y-scroll !box-border px-12 pb-6 !rounded-sm max-h-[calc(100vh-160px)] ">
            <div className="flex w-full sticky top-0 bg-white justify-end pt-6 items-center">
              <IconButton onClick={() => onHandleClose()}>
                <XMarkIcon className="text-black w-6 h-6" />
              </IconButton>
            </div>
            <h3 className="font-gilroy font-bold text-form-header text-h4 mb-4.75">
              View Task
            </h3>
            <TabContainer
              tabs={tabs}
              onTabChange={(index) => setActiveTab(index)}
              activeTab={activeTab}
            />
            <div className="w-full mt-6.5 pb-4">{renderContent()}</div>
          </Paper>
        </div>
      </Modal>
    </>
  );
}

export const MediaStreamRecordCompleted = ({
  execution,
}: {
  execution: any;
}) => {
  const startTime = execution?.created_at
    ? toYYMMddHHmmss(execution.created_at)
    : "N/A";
  const endTime = execution?.updated_at
    ? toYYMMddHHmmss(execution.updated_at)
    : "N/A";
  const failureReason = execution?.failure_reason || "N/A";
  const isCompleted = !execution?.failure_reason;

  return (
    <div className="flex flex-col gap-4.25">
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Start Time
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {startTime}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          End Time
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {endTime}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Failure Reason
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {failureReason}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Is Completed
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {isCompleted ? "Yes" : "No"}
        </p>
      </div>
    </div>
  );
};

export const MediaStreamUploadCompleted = ({
  execution,
}: {
  execution: any;
}) => {
  const startTime = execution?.created_at
    ? toYYMMddHHmmss(execution.created_at)
    : "N/A";
  const endTime = execution?.updated_at
    ? toYYMMddHHmmss(execution.updated_at)
    : "N/A";
  const failureReason = execution?.failure_reason || "N/A";
  const isCompleted = !execution?.failure_reason;
  const filePath = execution?.s3_file_path || null;

  return (
    <div className="flex flex-col gap-4.25">
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Start Time
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {startTime}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          End Time
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {endTime}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Failure Reason
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {failureReason}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Is Completed
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {isCompleted ? "Yes" : "No"}
        </p>
      </div>
      {filePath && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Audio File
          </p>
          <AudioPlayerUI audioFile={filePath} />
        </div>
      )}
    </div>
  );
};

export const MediaStreamTranscribingComplete = ({
  execution,
  filePath,
}: {
  execution: any;
  filePath?: string;
}) => {
  const startTime = execution?.created_at
    ? toYYMMddHHmmss(execution.created_at)
    : "N/A";
  const endTime = execution?.updated_at
    ? toYYMMddHHmmss(execution.updated_at)
    : "N/A";
  const failureReason = execution?.failure_reason || "N/A";
  const isCompleted = !execution?.failure_reason;
  const jobResult = execution?.job_result || null;
  const transcript =
    formatJSON5(jobResult)?.results?.[0]?.["transcript"] || null;

  console.log("Job result ", transcript);

  return (
    <div className="flex flex-col gap-4.25">
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Start Time
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {startTime}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          End Time
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {endTime}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Failure Reason
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {failureReason}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Is Completed
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {isCompleted ? "Yes" : "No"}
        </p>
      </div>
      {filePath && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Audio File
          </p>
          <AudioPlayerUI audioFile={filePath} />
        </div>
      )}
      {transcript && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Transcript
          </p>
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            {transcript}
          </p>
        </div>
      )}
    </div>
  );
};

export const MediaStreamAnalysisComplete = ({
  execution,
  filePath,
}: {
  execution: any;
  filePath?: string;
}) => {
  const startTime = execution?.created_at
    ? toYYMMddHHmmss(execution.created_at)
    : "N/A";
  const endTime = execution?.updated_at
    ? toYYMMddHHmmss(execution.updated_at)
    : "N/A";
  const failureReason = execution?.failure_reason || "N/A";
  const isCompleted = !execution?.failure_reason;
  const jobResult = execution?.job_result || null;
  const report = formatJSON(jobResult) || null;
  const summary = report?.summary || null;
  const complianceReport: {
    parameter: string;
    detected: string;
    explanation: string;
  }[] = [];
  report?.compliance_report &&
    Object.keys(report?.compliance_report).forEach((key) => {
      complianceReport.push({
        parameter: key?.replace(/_/g, " "),
        detected: report?.compliance_report?.[key]?.["detected"] ? "Yes" : "No",
        explanation: report?.compliance_report?.[key]?.["explanation"],
      });
    });

  const tableColumns: ITableColumn[] = [
    {
      name: "Parameter",
      dataKey: "parameter",
    },
    {
      name: "Detected",
      dataKey: "detected",
    },
    {
      name: "Explanation",
      dataKey: "explanation",
    },
  ];

  const namedEntities: {
    entity: string;
    value: string;
  }[] = [];

  report?.named_entities &&
    Object.keys(report?.named_entities).forEach((key) => {
      namedEntities.push({
        entity: key?.replace(/_/g, " "),
        value: report?.named_entities?.[key]?.join(", "),
      });
    });

  const namedEntitiesTableColumns: ITableColumn[] = [
    {
      name: "Entity",
      dataKey: "entity",
    },
    {
      name: "Value",
      dataKey: "value",
    },
  ];

  return (
    <div className="flex flex-col gap-4.25">
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Start Time
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {startTime}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          End Time
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {endTime}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Failure Reason
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {failureReason}
        </p>
      </div>
      <div className="flex flex-row items-center gap-4.25">
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          Is Completed
        </p>
        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
          {isCompleted ? "Yes" : "No"}
        </p>
      </div>
      {summary && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Summary
          </p>
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            {summary}
          </p>
        </div>
      )}
      {filePath && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Audio File
          </p>
          <AudioPlayerUI audioFile={filePath} />
        </div>
      )}
      {complianceReport?.length > 0 && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Compliance report
          </p>
          <DataTable
            columns={tableColumns}
            data={complianceReport}
            header="Compliance Report"
            hideHeaderSection
            hidePagination
          />
        </div>
      )}

      {namedEntities?.length > 0 && (
        <div className="flex flex-col items-start gap-4.25">
          <p className="text-sm font-normal text-placeholder first-letter:capitalize">
            Named Entities
          </p>
          <DataTable
            columns={namedEntitiesTableColumns}
            data={namedEntities}
            header="Named Entities"
            hideHeaderSection
            hidePagination
          />
        </div>
      )}
    </div>
  );
};

export const formatJSON = (jsonStr: string): any => {
  const repaired = jsonrepair(jsonStr);
  console.log("Repaired ", repaired);

  try {
    return JSON.parse(
      repaired
        ?.replace(/'/g, '"')
        .replace(/True/g, "true")
        .replace(/False/g, "false")
    );
  } catch (error) {
    return undefined;
  }
};

export const formatJSON5 = (jsonStr: string): any => {
  console.log("Json str ", jsonStr);
  try {
    return json5.parse(jsonStr);
  } catch (error) {
    return undefined;
  }
};
